import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import systemConfig from "../../config/systemConfiguration";
import { clearSignInCredentials, setCredentials } from "../../features/auth/authSlice";
import { baseRequestConfig } from "../config/requestConfigs";
const { entity } = systemConfig;

// console.log(7, { baseRequestConfig, customer: entity });

const baseQuery = fetchBaseQuery({
  baseUrl: baseRequestConfig.baseURL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.user.token;
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }

    headers.set("Entity-UuId", entity.uuId);
    return headers;
  }
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 403 || result?.error?.status === 403) {
    console.log("sending refresh token (baseApi)");
    // send refresh token to get new access token
    const refreshResult = await baseQuery("/authenticate/refresh", api, extraOptions);
    console.log(refreshResult);
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      // store the new token
      api.dispatch(setCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      return await baseQuery(args, api, extraOptions);
    } else {
      console.log("Invalid credentials issues");
      api.dispatch(clearSignInCredentials());
    }
  }

  return result;
};

export const baseApi = createApi({
  reducerPath: "authApi",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({
    logIn: builder.query({
      query: (credentials) => ({
        url: "/authenticate",
        method: "POST",
        body: { ...credentials }
      })
    }),
    logOut: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate",
        method: "DELETE",
        body: { ...credentials }
      })
    }),
    userRegister: builder.query({
      query: ({ emailAddress, registrationKey }) => ({
        url: `/authenticate/registration?emailAddress=${emailAddress}&registrationKey=${registrationKey}`,
        method: "GET"
      })
    }),
    register: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate/registration",
        method: "POST",
        body: { ...credentials }
      })
    })    ,
    checkUserPasswordResetStatus: builder.query({
      query: ({ emailAddress, key }) => `/authenticate/resetPassword?emailAddress=${emailAddress}&key=${key}`
    }),
    userPasswordResetRequest: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate/resetPassword",
        method: "POST",
        body: { ...credentials }
      })

    }),
    userPasswordResetSubmit: builder.mutation({
      query: (credentials) => ({
        url: "/authenticate/resetPassword",
        method: "PUT",
        body: { ...credentials }
      })

    })
  })
});

export const { useLogInQuery, useLogOutMutation, useUserRegisterQuery, useRegisterMutation,
  useCheckUserPasswordResetStatusQuery,useUserPasswordResetRequestMutation,useUserPasswordResetSubmitMutation
} = baseApi;
