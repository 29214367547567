import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  theme: "light-theme",
  sidebar: { expanded: true },
  dashboard: [],
  filters: [],
  charts: [],
  tables: { columnOptions: [] },
  features:  []
};

function findExistingFilter(filters, filterName) {
  return filters.find((filter) => filter.name === filterName);
}

function findExistingChart(charts, chartName) {
  return charts.find((chart) => chart.name === chartName);
}

function findExistingTableHidden(columnOptions, tableName) {
  const columnOption = columnOptions ? columnOptions.find((table) => table.name === tableName) : undefined;

  return columnOption ? columnOption.hidden : undefined;
}

function findExistingDashboardFeature(dashboard, name) {
  return dashboard.find((feature) => feature.name === name);
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: initialState,
  reducers: {
    setDarkTheme: (state) => {
      state.theme = "dark-theme";
    },
    setLightTheme: (state) => {
      state.theme = "light-theme";
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    toggleTheme: (state) => {
      state.theme = state.theme === "light-theme" ? "dark-theme" : "light-theme";
    },
    expandSidebar: (state) => {
      state.sidebar = { expanded: true };
    },
    minimizeSidebar: (state) => {
      state.sidebar = { expanded: false };
    },
    setFilterOptionsFeature: (state, action) => {
      const { filterOptions } = action.payload;
      if (findExistingFilter(state.filters, action.payload.name)) {
        state.filters = state.filters.map((feature) => {
          if (feature.name === action.payload.name) {
            feature.filterOptions = filterOptions;
          }
          return feature;
        });
      } else {
        state.filters.push({ name: action.payload.name, filterOptions });
      }
    },
    setPerPageFeature: (state, action) => {
      const { perPage } = action.payload;
      if (findExistingFilter(state.filters, action.payload.name)) {
        state.filters = state.filters.map((filter) => {
          if (filter.name === action.payload.name) {
            filter.perPage = perPage;
          }
          return filter;
        });
      } else {
        state.filters.push({ name: action.payload.name, perPage });
      }
    },
    setIsFilterFeature: (state, action) => {
      const { isFilter } = action.payload;
      if (findExistingFilter(state.filters, action.payload.name)) {
        state.filters = state.filters.map((filter) => {
          if (filter.name === action.payload.name) {
            filter.isFilter = isFilter;
          }
          return filter;
        });
      } else {
        state.filters.push({ name: action.payload.name, isFilter });
      }
    },
    setIsShowChartsFeature: (state, action) => {
      const { isShowCharts, name } = action.payload;
      if (findExistingChart(state.charts, name)) {
        state.charts = state.charts.map((feature) => {
          if (feature.name === action.payload.name) {
            feature.isShowCharts = isShowCharts;
          }
          return feature;
        });
      } else {
        state.charts.push({ name: action.payload.name, isShowCharts });
      }
    },
    setIsDisplayLiveOnlyFeature: (state, action) => {
      const { isDisplayLiveOnly, name } = action.payload;
      if (findExistingDashboardFeature(state.dashboard, name)) {
        state.dashboard = state.dashboard.map((feature) => {
          if (feature.name === name) {
            feature.isDisplayLiveOnly = isDisplayLiveOnly;
          }
          return feature;
        });
      } else {
        state.dashboard.push({ name, isDisplayLiveOnly });
      }
    },
    setTableHiddenOptionFeature: (state, action) => {
      const { hidden, name } = action.payload;
      if (findExistingTableHidden(state.tables.columnOptions, name)) {
        state.tables.columnOptions = state.tables.columnOptions.map((feature) => {
          if (feature.name === action.payload.name) {
            feature.hidden = hidden;
          }
          return feature;
        });
     
      } else {
        if (!state.tables) {
          console.log({state, action });
          state.tables = { columnOptions: [] };
        }
        state.tables.columnOptions.push({ name, hidden });
      }

      console.log({tables:state.tables, action });
    }
  }
});

export const {
  setLightTheme,
  setDarkTheme,
  toggleTheme,
  setTheme,
  expandSidebar,
  minimizeSidebar,
  setFilterOptionsFeature,
  setPerPageFeature,
  setIsFilterFeature,
  setIsShowChartsFeature,
  setIsDisplayLiveOnlyFeature,
  setTableHiddenOptionFeature
} = settingsSlice.actions;

export const selectCurrentTheme = (state) => state.settings.theme;
export const selectCurrentSidebar = (state) => state.settings.sidebar;
export const getFilterOptionsFeature = (name) => (state) => findExistingFilter(state.settings.filters, name)?.filterOptions;
export const getPerPageFeature = (name) => (state) => findExistingFilter(state.settings.filters, name)?.perPage || 10;
export const getIsFilterFeature = (name) => (state) => findExistingFilter(state.settings.filters, name)?.isFilter || false;
export const getIsShowChartsFeature = (name) => (state) => findExistingChart(state.settings.charts, name)?.isShowCharts || false;
export const getIsDisplayLiveOnlyFeature = (name) => (state) => findExistingFilter(state.settings.dashboard, name)?.isDisplayLiveOnly || false;
export const getTableHiddenOptionFeature = (name) => (state) => findExistingTableHidden(state.settings.tables.columnOptions, name);

export default settingsSlice.reducer;
