import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { setSystemSignOut, validateTokenExpiry, setRefreshTokenAttempt } from "../features/auth/authSlice";
import Loading from "./Loading";

function getErrorStatus(error) {
  if (error.originalStatus) {
    return error.originalStatus;
  }

  return error.status;
}

function getErrorDescription(error) {
  if (error.data?.error) {
    return error.data.error;
  } else if (error.data.message) {
    return error.data.message;
  
  } else if (error.error) {
    return error.error;
  }
  return error.data;
}

function isTokenExpiredError(error) {
  if (!error?.data || error.status !== 403) {
    return false;
  } else {
    const { errorMessage } = error.data;
    return errorMessage.toLowerCase() === "Token expired".toLowerCase();
  }
}

class OnError extends React.Component {
  constructor(props) {
    super(props);
    const { error } = this.props;
    // console.log({ props: this.props });
    this.state = {
      isTokenExpired: false,
      refreshToken: null,
      status: getErrorStatus(error),
      errorDescription: getErrorDescription(error),
      error,
      forbiddenError: error.status === 403,
      unAuthorizedError: error.status === 401
    };
  }

  componentDidMount() {
    const { error } = this.props;
    const { dispatch } = this.props;

    console.log("componentDidMount");

    // Dispatch action to validate token expiry
    dispatch(validateTokenExpiry());

    // Get error status and description

    const status = getErrorStatus(error);
    const errorDescription = getErrorDescription(error);

    // Check if token is expired
    const isTokenExpired = isTokenExpiredError(error);

    // Update state
    this.setState({
      isTokenExpired,
      status,
      errorDescription
    });
  }

  componentWillUnmount() {
   // console.log("componentWillUnmount");
  }

  handleLogout() {
    const { dispatch, status } = this.props;
    dispatch(setSystemSignOut({ status }));
    this.navigate("/logout");
  }

  handleLoadPage(path) {
    this.navigate(path);
  }

  handleRefresh = () => {
    window.location.reload();
  };

  navigate(path) {
    this.props.history.push(path);
  }
  render() {
    const { dispatch } = this.props;
    const { tokenExpired, refreshToken, user } = this.props.auth;

    console.log("render");

    const { isTokenExpired, status, errorDescription, forbiddenError,error } = this.state;
    const {
      expired: refreshTokenExpired,
      attempts: refreshTokenAttempt,
      processStarted: refreshTokenProcessStarted,
      processCompleted: refreshTokenProcessCompleted,
      processCompletedSuccessfully: refreshTokenProcessCompletedSuccessfully
    } = refreshToken;

    const shouldRefreshPage = forbiddenError && refreshTokenProcessCompleted && refreshTokenProcessCompletedSuccessfully;
    const shouldAttemptTokenRefresh = forbiddenError && isTokenExpired && tokenExpired && refreshTokenExpired === false && (!refreshTokenAttempt || refreshTokenAttempt < 4);
    const shouldWaitForTokenRefresh = forbiddenError && (refreshTokenProcessStarted || !refreshTokenProcessCompleted);

    let shouldLogOut = false;

    console.log({
      forbiddenError,
      shouldLogOut,
      shouldRefreshPage,
      refreshTokenAttempt,
      shouldAttemptTokenRefresh,
      refreshTokenProcessCompletedSuccessfully,
      refreshTokenProcessCompleted,
      tokenExpired,
      error,
      status,
      errorDescription
    });

    if (shouldRefreshPage) {
      this.handleRefresh();
    } else if (shouldAttemptTokenRefresh) {
      const currentAttempt = refreshTokenAttempt ? refreshTokenAttempt + 1 : 1;
      dispatch(setRefreshTokenAttempt({ refreshTokenAttempt: currentAttempt }));
    } else if (shouldWaitForTokenRefresh) {
    } else {
      shouldLogOut = (isTokenExpired && tokenExpired && refreshTokenExpired) || refreshTokenAttempt > 3;
    }

    return shouldWaitForTokenRefresh ? (
      <Loading isLoading={true} loadingText="Token expired, waiting for the refresh Token to be completed" isLogin={true} />)
      : shouldRefreshPage ? (<Loading isLoading={true} loadingText="Token is now current and ready to be used" isLogin={true} />)
      : shouldAttemptTokenRefresh ? (<Loading isLoading={true} loadingText="Token expired, attempting to start Token refresh" isLogin={true} />)
      : (<UserNavigation status={status} shouldLogOut={shouldLogOut} user={user} errorDescription={errorDescription} location={"location.pathname"} />);
  }
}


function UserNavigation({ user, status, errorDescription, shouldLogOut, location }) {
  const userRoleOrAccessIssue = status === 500 ? false : status === 403 || !user?.role?.actions || !user?.role?.permissions;
  const isSignedIn = user?.token && user?.isSignedIn;
  const errorMessage = errorDescription.message || errorDescription.errorMessage || errorDescription;
  // console.log(97, { location, status, errorDescription, user });
  return userRoleOrAccessIssue ? (
    <Navigate to="/login" state={{ from: location }} replace />
  ) : isSignedIn ? (
    <DisplayError status={status} errorDescription={errorMessage} />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
}

function DisplayError({ status, errorDescription }) {
  return (
    <section className="flex items-center h-screen px-16 overflow-hidden bg-primary">
      <div className="container flex flex-col items-center justify-center px-5 mx-auto my-8">
        <div className="max-w-md text-center">
          <h2 className="mb-8 text-4xl font-extrabold text-background">
            <span className="sr-only">Error</span>
            {status || 500}
          </h2>
          <p className="text-xl font-semibold md:text-2xl text-background">We're sorry, but it seems like we've encountered an issue on the requested web page.</p>
          <p className="mt-4 mb-10 text-background md:text-2xl">Details: {errorDescription || "Check your Internet Connection"}</p>
          <a rel="noopener noreferrer" href="/" className="px-8 py-3 font-semibold underline rounded text-background hover:shadow-md">
            Back to homepage
          </a>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(OnError);
