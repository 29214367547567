import React, { useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Layout, NoRecords, OnError, PageFilter, Pagination, PrimaryHeader } from "../../components";
import { getNameFromUrlPath } from "../../core/utilities";

import Api from "../../api";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import { SystemTables, Table } from "../../components/table";
import FilterOptions from "../../filter/FilterOptions";

import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

function UserDetails() {
  const { userId } = useParams();
  const { state: locationState } = useLocation();
  const { data: userInfo, isLoading, isError, error } = Api.users.useGetUserQuery(userId);

  const fromLocation = {
    path: locationState?.from.pathname || "/users",
    name: getNameFromUrlPath(locationState?.from.pathname) || "Users"
  };
  //console.log({device});

  return isError ? <OnError error={error} /> : isLoading ? <TablesSkeleton isLoading={isLoading} /> : <DisplayUserDetails userInfo={userInfo} fromLocation={fromLocation} />;
}

function DisplayUserDetails({ userInfo, fromLocation }) {
  const [displayData, setDisplayData] = useState(userInfo.auditTrails);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({ displayData });
  const navigate = useNavigate();
  const { userId } = useParams();
  const { fullName } = userInfo.user;

  const handleFilterDataChange = (filteredData) => {
    setIsFiltered(filteredData.length !== userInfo.auditTrails.length);
    setDisplayData(filteredData);
  };

  const handleTableDoubleClick = (row, identifier) => {
    console.log({ identifier, row });

    if (row.dashboardUrl) {
      const url = row.paramId ? `${row.dashboardUrl}/${row.paramId}` : row.dashboardUrl;
      navigate(url);
    }
  };

  const filterOptions = () => {
    const option = new FilterOptions();
    return option.getUserAuditTrails();
  };

  const breadcrumbItems = () => {
    const items = [];
    if (fromLocation && fromLocation.name) {
      items.push({ name: fromLocation.name, path: fromLocation.path });
    } else {
      items.push({ name: "Users", path: "/users" });
    }
    items.push({ name: `${fullName}`, path: `/users/${userId}` });

    return items;
  };

  console.log({ at: userInfo.auditTrails, user: userInfo.user });

  return (
    <Layout>
      <PrimaryHeader breadcrumbItems={breadcrumbItems()}>
        <div className="flex">
          <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
        </div>
      </PrimaryHeader>
      <Filter enabled={isFilter} data={userInfo.auditTrails} filterOptions={filterOptions()} handleFilterDataChange={handleFilterDataChange} />
      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"User Audit Trails"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />
        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={SystemTables.userAuditTrails}
          initialSort={{ key: "timestamp", order: "desc" }}
          onTableRowDoubleClick={handleTableDoubleClick}
          includeSequentialId={true}
        />

        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}

export default UserDetails;
