import { api } from "./api";
import Constant from "../../constant";

export const devicesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllDevices: builder.query({
      query: () => "/devices",
      keepUnusedDataFor: 120
    }),
    getDevice: builder.query({
      query: (id) =>`/devices/${id}`
    }),
    getAllDevicesEvents: builder.query({
      query: (activeOnly=false) => `/devices/events?activeOnly=${activeOnly}`
    }),
    getAllDevicesSmsAlerts: builder.query({
      query: (activeOnly=false) => "/devices/smsAlerts"
    }),
    getDeviceEvents: builder.query({
      query: (id,activeOnly=false) => `/devices/events/${id}?activeOnly=${activeOnly}`
    }),
    getDeviceLogs: builder.query({
      query: (id) => `/devices/logs/${id}`
    }),    
    getDeviceSmsAlerts: builder.query({
      query: (id,activeOnly=false) => `/devices/smsAlerts/${id}`
    }),
    getDevicesDailyConnectivityStatistics: builder.query({
      query: (numberOfDays=7) => `/devices/dailyConnectStatistics?numberOfDays=${numberOfDays}`
    }),
    getDeviceCameraFeeds: builder.query({
      query: (id) => `/devices/cameraFeeds/${id}?daysToInclude=${Constant.System.CameraFeeds.NoOfDaysToInclude}`
    }),
    getDeviceTripPoints: builder.query({
      query: (id) => `/devices/tripPoints/${id}?daysToInclude=${Constant.System.Devices.TripPoints.NoOfDaysToInclude}`
    })
  })
});

export const { useGetAllDevicesQuery, useGetDeviceQuery, useGetAllDevicesEventsQuery, useGetDeviceEventsQuery,
  useGetAllDevicesSmsAlertsQuery, useGetDeviceLogsQuery, useGetDeviceSmsAlertsQuery, useGetDeviceTripPointsQuery,
  useGetDevicesDailyConnectivityStatisticsQuery, useLazyGetDevicesDailyConnectivityStatisticsQuery,
  useGetDeviceCameraFeedsQuery } = devicesApi;