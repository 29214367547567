import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Layout } from "../components";

export default function DeviceDetailsSkeleton({ isLoading }) {
  return (
    <Layout isLoading={isLoading} loadingText="Loading Vehicle">
      <header className="flex flex-row justify-between pr-4 rounded-sm shadow-lg bg-other col-span-full xl:col-span">
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
          <div className="flex flex-row items-center gap-2 px-5 py-4">
          <Skeleton width={250} height={15} />
          </div>
        </SkeletonTheme>
      </header>
      
      <section className="flex flex-row justify-between pr-4 rounded-sm col-span-full xl:col-span">
        <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
          <div className="flex flex-row items-center gap-2 px-5 py-4">
            <p className="inline-flex items-center group">
              <Skeleton width={100} height={30} />
            </p>

            <p className="inline-flex items-center group">
              <Skeleton width={100} height={30} />
            </p>

            <p className="inline-flex items-center group">
              <Skeleton width={100} height={30} />
            </p>
            <h2 className="inline-flex gap-2 font-normal">
              <Skeleton width={100} height={30} />{" "}
              <span className="hidden md:block">
                <Skeleton width={100} height={30} />
              </span>
            </h2>
          </div>
        </SkeletonTheme>
      </section>

      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
       <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <div className="flex flex-col items-left gap-2 px-5 py-4 bg-other">
         <Skeleton width={100} height={30} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
        </div>
        
        <div className="flex flex-col items-left gap-2 px-5 py-4 bg-other">
         <Skeleton width={100} height={30} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
        </div>

        <div className="flex flex-col items-left gap-2 px-5 py-4 bg-other">
         <Skeleton width={100} height={30} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
         <Skeleton width={150} height={15} />
        </div>
       
       </SkeletonTheme> 
      </section>

      <section className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 bg-other">
       <SkeletonTheme baseColor="var(--color-skeleton)" highlightColor="var(--color-background)">
        <div className="flex flex-col items-left gap-2 px-5 py-4">
        <Skeleton width={150} height={15} />
        <Skeleton width={200} height={60} />
        <Skeleton width={200} height={60} />
        <Skeleton width={200} height={60} />
        </div>
       
       </SkeletonTheme> 
      </section>

    </Layout>
  );
}
