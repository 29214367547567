import React, { useCallback, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { dateDisplay, dateTimeDisplay, isValidNumber, numberFormat, splitAlphaNumeric } from "../../core/utilities";
import { CameraIcon, VideoIcon } from "../icons";
import MediaModal from "../modal/MediaModal";
import TableHeaderSort from "./TableHeaderSort";
import TableDataTypes from "./options/TableDataTypes";

var _ = require("lodash");

function getAllColumnsForRowDisplayInfo(row, rowTypesInfo, identifier, onEditableBooleanAccessCheck) {
  const rowData = _.pick(
    row,
    rowTypesInfo.map((row) => row.key)
  );
  const allRowsDisplayInfo = [];

  for (const key in rowData) {
    const rowInfo = _.first(rowTypesInfo.filter((rt) => rt.key === key));
    const rawValue = rowData[key];
    const valueType = rowInfo.dataType;
    const isShown = rowInfo.isShown === undefined ? true : rowInfo.isShown;
    const isHidden = rowInfo.isHidden === undefined ? false : rowInfo.isHidden;
    const align = rowInfo.dataAlign ? rowInfo.dataAlign : isValidNumber(rawValue) ? "right" : "left";
    const link = rowInfo.dataLink ? rowInfo.dataLink.path.replace("{id}", row[rowInfo.dataLink.id]) : "";
    const click = rowInfo.dataClick ? { id: row[rowInfo.dataClick.id], data: row[rowInfo.dataClick.data] } : undefined;
    const editAllowed = false;

    const rowDisplayInfo = { align, link, click, label: rowInfo.dataLabel, valueType, isHidden, isShown, editAllowed, identifier, key };

    switch (rowInfo.dataType) {
      case TableDataTypes.Boolean:
        rowDisplayInfo.value = !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || "";
        break;

      case TableDataTypes.Button:
        rowDisplayInfo.value = identifier;
        rowDisplayInfo.valueObject = rowInfo.dataObject;
        rowDisplayInfo.valueKey = rowInfo.key;
        break;
      case TableDataTypes.DateTime:
        rowDisplayInfo.value = dateTimeDisplay(rawValue);
        break;
      case TableDataTypes.Date:
        rowDisplayInfo.value = dateDisplay(rawValue);
        break;

      case TableDataTypes.EditableBoolean:
        rowDisplayInfo.editAllowed = checkEditableBooleanUserAccess(key);
        rowDisplayInfo.value = !rawValue ? false : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || false;
        break;

      case TableDataTypes.Hundredth:
        rowDisplayInfo.value = numberFormat(rawValue, 2, "");
        break;

      case TableDataTypes.ImageVideoModal:
        rowDisplayInfo.rowLinkData = {
          url: row[rowInfo.dataObject.url],
          alt: row[rowInfo.dataObject.alt],
          headerOne: row[rowInfo.dataObject.headerOne],
          headerTwo: row[rowInfo.dataObject.headerTwo]
        };
        break;
      case TableDataTypes.Percentage:
        rowDisplayInfo.value = isValidNumber(rawValue) ? `${rawValue}%` : !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : "";
        break;

      case TableDataTypes.VehicleRegistration:
        rowDisplayInfo.value = splitAlphaNumeric(rawValue);
        break;

      default:
        rowDisplayInfo.value = !rawValue ? "" : typeof rawValue === "object" ? JSON.stringify(rawValue) : rawValue || "";
        break;
    }

    allRowsDisplayInfo.push(rowDisplayInfo);
  }
 
  return allRowsDisplayInfo;

  function checkEditableBooleanUserAccess(key) {
    if (onEditableBooleanAccessCheck) {
      return onEditableBooleanAccessCheck({ key });
    } else {
      return false;
    }
  }
}

function mergeNoneDataKeysToData(tableData, tableColumns) {
  if (!tableData) {
    return tableData;
  }
  if (tableData.length === 0) {
    return tableData;
  }

  const rowKeys = Object.keys(tableData[0]);
  const noneColumnKeys = tableColumns
    .map((column) => {
      return { key: column.key, dataType: column.dataType, dataObject: column.dataObject };
    })
    .filter((column) => {
      return !rowKeys.includes(column.key);
    });
  const mergedData = tableData.map((data, index) => {
    let mergedData = data;
    noneColumnKeys.forEach((column) => {
      let columnData = {};
      columnData[column.key] = { dataType: column.dataType, dataObject: column.dataObject, dataLabel: column.dataLabel };

      mergedData = Object.assign({}, mergedData, columnData);
    });
    return mergedData;
  });

  return mergedData;
}

function sequencedAndOrderedData({ tableData, sortKey, sortOrder, includeSequentialId, tableColumns }) {
  const mergedData = mergeNoneDataKeysToData(tableData, tableColumns);
  const sortedData = _.orderBy(mergedData, [sortKey], [sortOrder]);
  if (includeSequentialId) {
    return sortedData.map((data, index) => {
      return Object.assign({}, data, { __table_seq_id: index + 1 });
    });
  }
  return sortedData;
}

function sortData({ tableData, sortKey, sortOrder, pagingInfo, includeSequentialId, tableColumns }) {
  if (!sortKey) return tableData;

  const sortedData = sequencedAndOrderedData({ tableData, sortKey, sortOrder, includeSequentialId, tableColumns });
  const dataOffset = (pagingInfo.currentPage - 1) * pagingInfo.dataPerPage;

  const endOffset = dataOffset + pagingInfo.dataPerPage;
  const pagedData = sortedData?.slice(dataOffset, endOffset);

  //console.log({ dataOffset, pagedData, endOffset, pagingInfo,sortedData });

  return pagedData;
}

function setupHeader(tableColumns) {
  if (!tableColumns) {
    return [];
  }

  const headerInfo = tableColumns.map((column) => {
    const isShown = column.isShown === undefined ? true : column.isShown;
    const isHidden = column.isHidden === undefined ? false : column.isHidden;
    return {
      key: column.key,
      label: column.label,
      headerSort: column.dataSort || false,
      excludeHeader: column.excludeHeader || false,
      hideColumn: isShown === false || isHidden,
      canHide: column.canHide || false
    };
  });

  return headerInfo.filter((header) => header.hideColumn === false);
}

function Table({
  tableColumns,
  data,
  pagingInfo,
  initialSort,
  onContextMenu,
  onTableRowClick,
  onTableRowDoubleClick,
  onTableDataDoubleClick,
  firstColumnCheckBox,
  lastColumnCheckBox,
  includeSequentialId,
  outOfRange,
  onSelectedChanged,
  onButtonClick,
  onEditableBooleanChange,
  onEditableBooleanAccessCheck
}) {
  const [sortKey, setSortKey] = useState(initialSort?.key || "");
  const [sortOrder, setSortOrder] = useState(initialSort?.order || "");
  const [tableData, setTableData] = useState();

  const [identifierColumn] = useState(_.find(tableColumns, { dataIdentifier: true }));
  const [selectedRows, setSelectedRows] = useState([]);
  const headers = setupHeader(tableColumns);

  const rowTypesInfo = tableColumns
    ? tableColumns.map((column) => {
        return {
          key: column.key,
          dataType: column.dataType,
          dataAlign: column.dataAlign,
          dataObject: column.dataObject,
          dataClick: column.click,
          dataLink: column.link,
          dataLabel: column.label,
          isShown: column.isShown === undefined ? true : column.isShown,
          isHidden: column.isHidden === undefined ? false : column.isHidden
        };
      })
    : [];

  const sortedData = useCallback(
    () => sortData({ tableData: data, sortKey, sortOrder, pagingInfo, includeSequentialId, tableColumns: rowTypesInfo }),
    [data, sortKey, sortOrder, pagingInfo, includeSequentialId]
  );

  const onSort = (selectedSortKey) => {
    if (sortKey === selectedSortKey) {
      const order = sortOrder ? (sortOrder.toLowerCase() === "asc" ? "desc" : "asc") : "asc";
      setSortOrder(order);
    } else {
      setSortKey(selectedSortKey);
      setSortOrder("asc");
    }
    setSortKey(selectedSortKey);
    //console.log("onSort", { sortKey, sortOrder, selectedSortKey,data });
  };

  const onRowSelectedChange = (selected, e) => {
    let selection = [];

    if (selectedRows.includes(selected)) {
      selection = _.pull(selectedRows, selected);
    } else {
      selection = _.concat(selectedRows, selected);
    }

    setSelectedRows(selection);
    if (onSelectedChanged) {
      onSelectedChanged(selection);
    }
  };

  useEffect(() => {
    setTableData(sortedData());
  }, [sortedData, data]);

  // console.log({tableColumns,headers,rowTypesInfo})
  return (
    <div className="p-3 overflow-x-auto section-container">
      <table className="detail-table">
        {/* Table header */}
        <thead>
          <tr>
            {includeSequentialId ? <th></th> : null}
            {firstColumnCheckBox ? <th></th> : null}
            {headers.map((row) => {
              return (
                <th scope="col" className="px-6 py-3" key={row.key}>
                  <div className="flex items-center">
                    {!row.excludeHeader ? row.label : ""}
                    <TableHeaderSort headerSort={row.headerSort} headerKey={row.key} onSort={onSort} sortOrder={sortOrder} sortKey={sortKey} />
                  </div>
                </th>
              );
            })}
            {!firstColumnCheckBox && lastColumnCheckBox ? <th></th> : null}
          </tr>
        </thead>
        {/* Table body */}
        <tbody>
          {/* Row */}
          {tableData?.map((row, index) => {
            const identifier = identifierColumn?.key ? row[identifierColumn.key] : undefined;
            const isRowSelected = selectedRows && identifier ? selectedRows.includes(identifier) : false;

            const isOddRow = index % 2 === 1;
            return (
              <TableRow
                row={row}
                tableData={tableData}
                rowTypesInfo={rowTypesInfo}
                key={index}
                rowIndex={index}
                identifier={identifier}
                onContextMenu={onContextMenu}
                onTableDataDoubleClick={onTableDataDoubleClick}
                onTableRowDoubleClick={onTableRowDoubleClick}
                onTableRowClick={onTableRowClick}
                onButtonClick={onButtonClick}
                onEditableBooleanChange={onEditableBooleanChange}
                onEditableBooleanAccessCheck={onEditableBooleanAccessCheck}
                firstColumnCheckBox={firstColumnCheckBox}
                lastColumnCheckBox={lastColumnCheckBox}
                isOddRow={isOddRow}
                isRowSelected={isRowSelected}
                includeSequentialId={includeSequentialId}
                outOfRange={outOfRange}
                onRowSelectedChange={onRowSelectedChange}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function TableRow({
  tableData,
  row,
  rowTypesInfo,
  rowIndex,
  isOddRow,
  identifier,
  onContextMenu,
  onTableDataDoubleClick,
  onTableRowDoubleClick,
  onTableRowClick,
  firstColumnCheckBox,
  lastColumnCheckBox,
  isRowSelected,
  includeSequentialId,
  outOfRange,
  onRowSelectedChange,
  onButtonClick,
  onEditableBooleanChange,
  onEditableBooleanAccessCheck
}) {
  const location = useLocation();
  const onRowContextMenu = (event, identifier, row) => {
    if (onContextMenu) {
      onContextMenu(event, identifier, row);
    }
  };

  const onTableDataDoubleClickEvent = (rowInfo, identifier) => {
    if (onTableDataDoubleClick) {
      onTableDataDoubleClick(rowInfo, identifier);
    }
  };

  const onTableRowDoubleClickEvent = (row, identifier) => {
    if (onTableRowDoubleClick) {
      onTableRowDoubleClick(row, identifier);
    }
  };

  const onTableRowClickEvent = (row, identifier) => {
    if (onTableRowClick && row.click) {
      onTableRowClick(row.click, identifier);
    }
  };

  const checkIfOutOfRange = (row) => {
    if (!outOfRange) {
      return false;
    }

    if (outOfRange.includeNull && row[outOfRange.key] === null) {
      return true;
    }

    if (outOfRange.belowLimit) {
      return outOfRange.belowLimit > row[outOfRange.key];
    }

    if (outOfRange.aboveLimit) {
      return outOfRange.aboveLimit < row[outOfRange.key];
    }
    return false;
  };

  const isOutOfRange = checkIfOutOfRange(row);
  /*
  if (isOutOfRange) {
    console.log({lookedAt:row[outOfRange.key], what:row.__table_seq_id,row, isOutOfRange,outOfRange });
  }*/

  return (
    <tr
      key={identifier}
      className="hover:bg-brand text-primary hover:text-other"
      onContextMenu={(event) => onRowContextMenu(event, identifier, row)}
      onDoubleClick={() => onTableRowDoubleClickEvent(row, identifier)}
    >
      {includeSequentialId ? (
        <td key={row["__table_seq_id"]} className="p-2">
          <div className="float-right">{row["__table_seq_id"]}</div>
        </td>
      ) : null}
      {firstColumnCheckBox ? (
        <td className="w-4 p-4" key={identifier}>
          <div className="flex items-center">
            <input
              id="checkbox-table-option"
              type="checkbox"
              value={isRowSelected ? "1" : ""}
              onChange={(e) => onRowSelectedChange(identifier, e)}
              className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-option" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      ) : null}
      {getAllColumnsForRowDisplayInfo(row, rowTypesInfo, identifier, onEditableBooleanAccessCheck)
        .filter((rowInfo) => rowInfo.isHidden === false && rowInfo.isShown)
        .map((rowInfo, columnIndex) => {
          //console.log(rowInfo);
          //console.log({ alt: rowInfo.data?.alt });

          return (
            <td key={columnIndex} className="p-2" data-row-identifier={identifier} onDoubleClick={() => onTableDataDoubleClickEvent(rowInfo, identifier)}>
              {rowInfo.link ? (
                <Link to={rowInfo.link} state={{ from: location }}>
                  <DisplayTableRow tableData={tableData} rowTypesInfo={rowTypesInfo} rowInfo={rowInfo} isOutOfRange={isOutOfRange} rowIndex={rowIndex} columnIndex={columnIndex} />
                </Link>
              ) : (
                <DisplayTableRow
                  tableData={tableData}
                  rowTypesInfo={rowTypesInfo}
                  rowInfo={rowInfo}
                  isOutOfRange={isOutOfRange}
                  rowIndex={rowIndex}
                  columnIndex={columnIndex}
                  onButtonClick={onButtonClick}
                  onEditableBooleanChange={onEditableBooleanChange}
                  onClickEvent={() => onTableRowClickEvent(rowInfo, identifier)}
                />
              )}
            </td>
          );
        })}
      {!firstColumnCheckBox && lastColumnCheckBox ? (
        <td className="w-4 p-4">
          <div className="flex items-center">
            <input
              id="checkbox-table-option"
              type="checkbox"
              checked={isRowSelected}
              onChange={(e) => onRowSelectedChange(identifier, e)}
              className="w-4 h-4 text-green-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
            />
            <label htmlFor="checkbox-table-option" className="sr-only">
              checkbox
            </label>
          </div>
        </td>
      ) : null}
    </tr>
  );
}

function DisplayTableRow({ tableData, rowTypesInfo, rowInfo, isOutOfRange, onButtonClick, onClickEvent, onEditableBooleanChange, rowIndex, columnIndex }) {
  const { valueType, rowLinkData, align, click, value } = rowInfo;

  /*
  if (typeof value === 'object') {
    return <div className={`${isOutOfRange ? "text-red-500" : ""} ${align === "right" ? "float-right" : "float-left"}`}>{JSON.stringify(value)}</div>
  }
  */
  // console.log({rowInfo})

  return (
    <div>
      {valueType === TableDataTypes.ImageVideoModal ? (
        <DisplayImageVideoModalRow tableData={tableData} rowTypesInfo={rowTypesInfo} rowLinkData={rowLinkData} rowIndex={rowIndex} columnIndex={columnIndex} />
      ) : valueType === TableDataTypes.Boolean ? (
        <DisplayBooleanRow rowInfo={value} />
      ) : valueType === TableDataTypes.EditableBoolean ? (
        <DisplayEditableBooleanRow rowInfo={rowInfo} onEditableBooleanChange={onEditableBooleanChange} />
      ) : valueType === TableDataTypes.Button ? (
        <DisplayButtonRow rowInfo={rowInfo} onButtonClick={onButtonClick} />
      ) : (
        <div className={`${click ? "cursor-pointer" : ""} ${isOutOfRange ? "text-red-500" : ""} ${align === "right" ? "float-right" : "float-left"}`} onClick={onClickEvent}>
          {value}
        </div>
      )}
    </div>
  );
}

function DisplayButtonRow({ rowInfo, onButtonClick }) {
  const { label, valueKey: buttonKey, value: id } = rowInfo;

  return (
    <button className="border-1 btn border-brand hover:opacity-80" onClick={() => onButtonClick({ buttonKey, id })}>
      {label}
    </button>
  );
}

function DisplayImageVideoModalRow({ tableData, rowTypesInfo, rowLinkData, rowIndex, columnIndex }) {
  const [modalRowIndex, setModalRowIndex] = useState(rowIndex);
  const [modalRowLinkData, setModalRowLinkData] = useState(rowLinkData);
  const [modalVisible, setModalVisible] = useState(false);
  const [previousNextInfo, setPreviousNextInfo] = useState({ previousMediaExists: rowIndex > 0, nextMediaExists: rowIndex < tableData.length - 1 });

  const changeModalRowIndex = (rowIndex) => {
    const tableRow = tableData[rowIndex];
    const rows = getAllColumnsForRowDisplayInfo(tableRow, rowTypesInfo);
    const rowLinkData = rows[columnIndex].rowLinkData;

    setModalRowIndex(rowIndex);
    setModalRowLinkData(rowLinkData);
    setPreviousNextInfo({ previousMediaExists: rowIndex > 0, nextMediaExists: rowIndex < tableData.length - 1 });
  };

  const handleMediaModalPreviousClick = () => {
    const rowIndex = modalRowIndex > 0 ? modalRowIndex - 1 : 0;

    if (rowIndex !== modalRowIndex) {
      changeModalRowIndex(rowIndex);
    }
  };

  const handleMediaModalNextClick = () => {
    const rowIndex = modalRowIndex < tableData.length - 1 ? modalRowIndex + 1 : modalRowIndex;

    if (rowIndex !== modalRowIndex) {
      changeModalRowIndex(rowIndex);
    }
  };

  const handleDivClick = () => {
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
  };

  const getFileExtension = (url) => {
    return url.split(".").pop().toLowerCase();
  };

  const isImage = (url) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
    const extension = getFileExtension(url);
    return imageExtensions.includes(extension);
  };

  const isVideo = (url) => {
    const videoExtensions = ["mp4", "avi", "mov", "mkv", "wmv"];
    const extension = getFileExtension(url);
    return videoExtensions.includes(extension);
  };

  return (
    <>
      {rowLinkData ? (
        <div className="flex items-center space-x-2" onClick={handleDivClick}>
          {isImage(rowLinkData.url) ? <CameraIcon color="var(--color-primary)" /> : null}
          {isVideo(rowLinkData.url) ? <VideoIcon color="var(--color-primary)" /> : null}
          <span>{rowLinkData.alt}</span>
        </div>
      ) : (
        <div></div>
      )}

      {modalVisible && (
        <MediaModal
          closeModal={closeModal}
          rowLinkData={modalRowLinkData}
          onPreviousClick={handleMediaModalPreviousClick}
          onNextClick={handleMediaModalNextClick}
          previousNextInfo={previousNextInfo}
        />
      )}
    </>
  );
}

function DisplayBooleanRow({ rowInfo }) {
  return (
    <div className="flex items-center">
      <input id="checkbox-table-option" type="checkbox" checked={rowInfo} readOnly={true} className="w-4 h-4 rounded text-secondary bg-disabled border-disabled focus:ring-brand focus:ring-2" />
      <label htmlFor="checkbox-table-option" className="sr-only">
        checkbox
      </label>
    </div>
  );
}

function DisplayEditableBooleanRow({ rowInfo, onEditableBooleanChange }) {
  const { key, value, identifier, editAllowed } = rowInfo;

  const handleValueChange = () => {
    if (onEditableBooleanChange && editAllowed) {
      onEditableBooleanChange({ id: identifier, key, value });
    }
  };
  return (
    <div className="flex items-center">
      <input
        id="checkbox-table-option"
        type="checkbox"
        checked={value}
        readOnly={!editAllowed}
        onChange={() => handleValueChange()}
        className={editAllowed ? "text-brand focus:ring-brand focus:ring-2" : "w-4 h-4 text-secondary bg-disabled border-disabled focus:right-2 focus:ring-disabled"}
      />
      <label htmlFor="checkbox-table-option" className="sr-only">
        checkbox
      </label>
    </div>
  );
}

export function initializeTableColumns(columns, currentHiddenColumns) {
  const currentColumnsValid = currentHiddenColumns && Array.isArray(currentHiddenColumns) ? true : false;
  const tableColumns = columns.map((column) => {
    column.isShown = column.isShown === undefined ? true : column.isShown;
    column.isHidden = column.isHidden === undefined ? (currentColumnsValid ? currentHiddenColumns.includes(column.key): false ): column.isHidden;

    return column;
  });

  return tableColumns;
};

export default Table;
