import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Api from "../../api";
import { Layout, LeftArrowIcon, NoRecords, OnError, PageFilter, Pagination } from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import { SystemTables, Table } from "../../components/table";
import SystemFeatures from "../../constant/systemFeatures";
import { isValidArrayWithData } from "../../core/utilities";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";

export default function DashboardCameraFeeds() {
  const { data: vehiclesDashboardCameraFeeds = [], isLoading, isError, error } = Api.vehicles.useGetAllVehiclesDashboardCameraFeedsQuery();

  const vehiclesDashboardCameraFeedsFound = isValidArrayWithData(vehiclesDashboardCameraFeeds);

  //console.log("Debug:", { vehiclesDashboardCameraFeeds, isLoading, isError, error });
  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <DisplayDashboardCameraFeeds cameraFeeds={[]} isLoading={isLoading} />
  ) : vehiclesDashboardCameraFeedsFound ? (
    <DisplayDashboardCameraFeeds cameraFeeds={vehiclesDashboardCameraFeeds} />
  ) : (
    <NoRecords title="Dashboard Camera Feeds" message="No Vehicles Dashboard Camera Feeds found." />
  );
}

const PROCESS_FEATURE = SystemFeatures.DASHBOARD_CAMERA_FEEDS;

function DisplayDashboardCameraFeeds({ cameraFeeds, isLoading = false }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(cameraFeeds);
  const [isFilter, setIsFilter] = useState(currentIsFilter);
  const [isFiltered, setIsFiltered] = useState(false);

  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({
    displayData,
    currentItemsPerPage
  });

  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(cameraFeeds);
  }, [cameraFeeds]);

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  const handleFilterDataChange = (filteredData) => {
    // console.log({ filteredData });
    setIsFiltered(filteredData.length !== cameraFeeds.length);
    if (filteredData.length > 0) {
      setDisplayData(filteredData);
    } else {
      setDisplayData(cameraFeeds);
    }
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getCameraFeedsOptions();
  };

  // console.log("Debug:", { cameraFeeds, displayData });

  return (
    <Layout isLoading={isLoading} loadingText="Loading Vehicles Dashboard Camera Feeds">
      <header className="header-justify-between">
        <div className="px-5 py-4 ">
          <div className="inline-flex items-center gap-2 text-primary">
            <Link to="/">
              <p className="inline-flex items-center font-semibold group">
                <LeftArrowIcon classes="h-4 w-4 group-hover:-translate-x-2 fill-current" />
                Dashboard /
              </p>
            </Link>
            <h2 className="inline-flex gap-2 font-normal">List of Vehicles with Dashboard Camera Feeds</h2>
          </div>
        </div>
        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
      </header>
      <Filter
        enabled={isFilter}
        data={cameraFeeds}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        feature={PROCESS_FEATURE}
        defaultFilterOptions={defaultFilterOptions}
      />

      <section className="flex flex-col gap-8 ">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"Camera Feeds"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />

        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={SystemTables.dashboardCameraFeeds}
          initialSort={{ key: "last_camera_footage", order: "desc" }}
        />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
