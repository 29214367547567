import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";

var moment = require("moment");

function DateTimeRangeSelect({ onChange, noFutureDates, value, key }) {
  const [startDate, setStartDate] = useState(value[0]);
  const [endDate, setEndDate] = useState(value[1]);

  const today = moment();

  useEffect(() => {
    if (startDate >= endDate) {
      onChange({ startDate, endDate });
    }
  }, [startDate, endDate, onChange]);

  const clearDates = (event) => {
    event.preventDefault();
    setStartDate(null);
    setEndDate(null);
  };

  const onStartDateChange = (date) => {
    if (date._isValid) {
      const aa = date.format("YYYY-MM-DD HH:mm:ss");
      console.log({ aa, date });
      setStartDate(date);
    } else {
      setStartDate(null);
    }
  };

  const isValidStartDate = (currentDate, selectedDate) => {
    if (endDate) {
      if (moment(endDate) < currentDate) {
        return false;
      }
    }

    if (noFutureDates && today < currentDate) {
      return false;
    }

    return true;
  };

  const onEndDateChange = (date) => {
    console.log({ date });
    if (date._isValid) {
      setEndDate(date);
    } else {
      setEndDate(null);
    }
  };

  const isValidEndDate = (currentDate, selectedDate) => {
    if (startDate) {
      if (moment(startDate) > currentDate) {
        return false;
      }
    }

    if (noFutureDates && today < currentDate) {
      return false;
    }

    return true;
  };
  console.log({ value: value });

  return (
    <div key={key} className="flex flex-row items-center justify-center gap-1 w-[30rem] mt-1 border-2  p-1">
      <Datetime
        dateFormat={"DD-MMM-YYYY"}
        timeFormat={"HH:mm:ss"}
        closeOnSelect={true}
        inputProps={{ placeholder: "Start DateTime", className: "single-input-field-filter" }}
        onChange={onStartDateChange}
        isValidDate={isValidStartDate}
        key={`start-date-${key}`}
        value={startDate ? moment(startDate) : undefined}
      />
      <div className="p-2"> to </div>
      <Datetime
        dateFormat={"DD-MMM-YYYY"}
        timeFormat={"HH:mm:ss"}
        closeOnSelect={true}
        inputProps={{ placeholder: "End DateTime", className: "single-input-field-filter  border-2 border-gray-300" }}
        onChange={onEndDateChange}
        isValidDate={isValidEndDate}
        key={`end-date-${key}`}
        value={endDate ? moment(endDate) : undefined}
      />
      <div>
        <button key={`clear-${key}`} className="btn-primary" onClick={(e) => clearDates(e)}>
          Clear
        </button>
      </div>
    </div>
  );
}

export default DateTimeRangeSelect;
