import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import systemConfig from "../../config/systemConfiguration";
import { clearSignInCredentials, setCredentials, setUserTokenFromRefresh,setRefreshTokenProcessStart,setRefreshTokenProcessCompleted } from "../../features/auth/authSlice";
import apiRequestConfig, { baseRequestConfig } from "../config/requestConfigs";
const { entity } = systemConfig;

const baseQuery = fetchBaseQuery({
  baseUrl: apiRequestConfig.baseURL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.user.token;
    headers.set("Entity-UuId", entity.uuId);

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

const authQuery = fetchBaseQuery({
  baseUrl: baseRequestConfig.baseURL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.user.token;
    headers.set("Entity-UuId", entity.uuId);

    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  }
});

const baseQueryWithReAuth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  // console.log("baseQueryWithReAuth",{result});

  if (result?.error?.originalStatus === 403 || result?.error?.status === 403) {
    console.log("sending refresh token (api)");
    api.dispatch(setRefreshTokenProcessStart());
    // send refresh token to get new access token
    authQuery("/authenticate/refresh", api, extraOptions)
      .then((refreshResult) => {
        console.log({refreshResult});
        if (refreshResult?.data) {
          const user = api.getState().auth.user;
          // store the new token
          api.dispatch( setUserTokenFromRefresh({ ...refreshResult.data, user }));

          
          // retry the original query with new access token
          baseQuery(args, api, extraOptions)
            .then((baseQueryResult) => {
              return baseQueryResult;
            })
            .catch((error) => {
              api.dispatch(clearSignInCredentials());
              return null;
            });
          
        } else {
          console.log("will navigate to login ...");
          
          api.dispatch(clearSignInCredentials());
          return null;
        }
      })
      .catch((error) => {
        console.log(error);
        api.dispatch(clearSignInCredentials());
        return null;
      });
  }

  return result;
};

export const api = createApi({
  reducerPath: "api",
  baseQuery: baseQueryWithReAuth,
  endpoints: (builder) => ({})
});
