import React, { useEffect, useState, useRef } from "react";
import DownArrowIcon from "../icons/DownArrowIcon";

const TableColumnSelectableOptions = ({ columnOptions, onColumnOptionsChange }) => {
  // State to track whether options are visible or not
  const [optionsVisible, setOptionsVisible] = useState(false);
  const optionsRef = useRef(null);

  useEffect(() => {
    // Function to handle clicks outside of the options container
    const handleClickOutside = (event) => {
      if (optionsRef.current && !optionsRef.current.contains(event.target)) {
        setOptionsVisible(false);
      }
    };


    // Add event listener when the options are visible
    if (optionsVisible) {
      document.addEventListener("click", handleClickOutside);
    } else {
      // Clean up the event listener when the options are not visible
      document.removeEventListener("click", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [optionsVisible]);

  const handleMouseLeave = () => {
    setOptionsVisible(false);
  };
  // Function to toggle visibility of options
  const toggleOptions = () => {
    setOptionsVisible(!optionsVisible);
  };

  // Function to handle selecting an option
  const handleSelectOption = (option, value) => {
    option.isHidden = value;
    const changedOptions = columnOptions.map((columnOption) => {
      if (columnOption.key === option.key) {
        console.log({ columnOption });
        columnOption.isHidden = value;
        console.log({ columnOption });
      }

      return columnOption;
    });

    if (onColumnOptionsChange) {
      onColumnOptionsChange(changedOptions);
    }
  };

  return (
    <div className="relative" ref={optionsRef}>
      {/* Button to toggle options visibility */}
      <button type="button" className="inline-flex items-center font-medium border text-xm btn-secondary" onClick={toggleOptions}>
        Columns
        <DownArrowIcon classes={"fill-primary group-hover:fill-other group-hover:animate-bounce opacity-80"} />
      </button>
      {/* Options container */}
      {optionsVisible && columnOptions && (
        <div className="absolute z-60 w-48 py-2 mt-2 rounded-lg shadow-lg bg-secondary text-primary" onMouseLeave={handleMouseLeave}>
          {/* List of selectable options with checkboxes */}
          {columnOptions
            .filter((option) => option.isShown)
            .map((option) => (
              <label key={option.key} className="block px-4 py-2 text-gray-800">
                <input type="checkbox" className="mr-2 leading-tight" checked={option.isHidden} onChange={() => handleSelectOption(option, !option.isHidden)} />
                <span className="text-sm">{option.label}</span>
              </label>
            ))}
        </div>
      )}
    </div>
  );
};

export default TableColumnSelectableOptions;
