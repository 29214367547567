import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Api from "../../api";
import { ConfirmationDialog, Layout, NoRecords, OnError, PageFilter, Pagination } from "../../components";
import Filter from "../../components/filter/Filter";
import FilterButton from "../../components/filter/FilterButton";
import { SystemTables, Table } from "../../components/table";
import Toast from "../../components/toast/Toast";
import Constant from "../../constant";
import SystemFeatures from "../../constant/systemFeatures";
import { isValidArrayWithData } from "../../core/utilities";
import { getFilterOptionsFeature, getIsFilterFeature, getPerPageFeature, setIsFilterFeature, setPerPageFeature } from "../../features/settings/settingsSlice";
import FilterOptions from "../../filter/FilterOptions";
import usePageFilter from "../../hooks/usePageFilter";
import TablesSkeleton from "../../skeletons/TablesSkeleton";

const PROCESS_FEATURE = SystemFeatures.DEVICE_SMS_ALERTS;

export default function DeviceSmsAlerts() {
  const { data: deviceSmsAlerts = [], isLoading, isError, error } = Api.devices.useGetAllDevicesSmsAlertsQuery(undefined, { pollingInterval: Constant.System.Devices.SmsAlerts.Polling });
  const deviceSmsAlertsFound = isValidArrayWithData(deviceSmsAlerts);

  return isError ? (
    <OnError error={error} />
  ) : isLoading ? (
    <TablesSkeleton isLoading={isLoading} rowCount={15} title={"Device Sms Alerts"} />
  ) : deviceSmsAlertsFound ? (
    <DisplayDeviceSmsAlerts deviceSmsAlerts={deviceSmsAlerts} />
  ) : (
    <NoRecords title="Device Sms Alerts" message="No Device Sms Alerts found." />
  );
}

function DisplayDeviceSmsAlerts({ deviceSmsAlerts, isLoading = false }) {
  const currentItemsPerPage = useSelector(getPerPageFeature(PROCESS_FEATURE));
  const currentIsFilter = useSelector(getIsFilterFeature(PROCESS_FEATURE));
  const defaultFilterOptions = useSelector(getFilterOptionsFeature(PROCESS_FEATURE));
  const [displayData, setDisplayData] = useState(deviceSmsAlerts);
  const [displayActiveOnlyData, setDisplayActiveOnlyData] = useState(false);
  const [dynamicTableColumn, setDynamicTableColumn] = useState(SystemTables.deviceSmsAlerts);
  const [isFilter, setIsFilter] = useState(false);
  const [isFiltered, setIsFiltered] = useState(currentIsFilter);
  const [commandConfirmation, setCommandConfirmation] = useState({ confirm: false });
  const [commandConfirmState, setCommandConfirmState] = useState(undefined);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [deviceCommandInfo, setDeviceCommandInfo] = useState({});
  const { numberOfItemsOnCurrentPage, itemsPerPage, currentPage, numberOfPages, totalNumberOfItems, handleItemsPerPageChange, handlePageClick } = usePageFilter({
    displayData,
    currentItemsPerPage
  });
  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayData(deviceSmsAlerts);
  }, [deviceSmsAlerts]);

  useEffect(() => {
    dispatch(setPerPageFeature({ name: PROCESS_FEATURE, perPage: itemsPerPage }));
  }, [dispatch, itemsPerPage]);

  useEffect(() => {
    dispatch(setIsFilterFeature({ name: PROCESS_FEATURE, isFilter }));
  }, [dispatch, isFilter]);

  useEffect(() => {
    if (displayActiveOnlyData) {
      setDisplayData(
        deviceSmsAlerts.filter((deviceEventData) => {
          return deviceEventData.override === false && deviceEventData.resolved === false;
        })
      );
      setDynamicTableColumn(
        SystemTables.deviceSmsAlerts.filter((tableColumn) => {
          return !tableColumn.excludeOnActive;
        })
      );
    } else {
      setDisplayData(deviceSmsAlerts);
      setDynamicTableColumn(SystemTables.deviceSmsAlerts);
    }
  }, [deviceSmsAlerts, displayActiveOnlyData]);

  useEffect(() => {
    const shouldClear = deviceCommandInfo.showToast || deviceCommandInfo.sendSuccess;
    const timer = setTimeout(() => {
      if (shouldClear) {
        setDeviceCommandInfo({});
      }
    }, 5000);

    return () => clearTimeout(timer);
  }, [deviceCommandInfo]);

  const handleFilterDataChange = (filteredData) => {
    const filtered = filteredData && deviceSmsAlerts ? filteredData.length !== deviceSmsAlerts.length : false;
    setIsFiltered(filtered);
    setDisplayData(filteredData);
  };

  const handleNegativeConfirmation = () => {
    setCommandConfirmation({ confirm: false });
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("NegativeConfirmation");
    }
  };

  const handlePositiveConfirmation = () => {
    setCommandConfirmation({ confirm: false });
    if (commandConfirmState === "AwaitingConfirmation") {
      setCommandConfirmState("PositiveConfirmation");
    }
  };

  const filterOptions = () => {
    const options = new FilterOptions();
    return options.getDeviceSmsAlertOptions();
  };

  const onSelectedTableRowChanged = (selected) => {
    const rows = deviceSmsAlerts.filter((device) => selected.includes(device.id));
    // console.log({ rows, selected });
    setSelectedDevices(rows);
  };

  console.log({ displayData, commandConfirmation, commandConfirmState });
  return (
    <Layout isLoading={isLoading} loadingText="Loading Device Events">
      <header className="header-justify-between">
        <div className="inline-flex px-5 py-4 ">
          <h2 className="font-semibold text-primary">Device SMS Alerts</h2>
        </div>

        <div>
          <input
            className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 dark:bg-neutral-600 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 dark:after:bg-neutral-400 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary dark:checked:bg-primary checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary dark:checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
            type="checkbox"
            role="switch"
            id="flexSwitchChecked"
            onChange={() => setDisplayActiveOnlyData(!displayActiveOnlyData)}
          />
          <label className="inline-block pl-[0.15rem] hover:cursor-pointer text-primary" htmlFor="flexSwitchChecked">
            Display Active Only
          </label>
        </div>

        <FilterButton isFilter={isFilter} setIsFilter={setIsFilter} />
      </header>

      <Filter
        enabled={isFilter}
        data={deviceSmsAlerts}
        filterOptions={filterOptions()}
        handleFilterDataChange={handleFilterDataChange}
        feature={PROCESS_FEATURE}
        defaultFilterOptions={defaultFilterOptions}
      />

      <section className="detail-section">
        <PageFilter
          itemsPerPage={itemsPerPage}
          setItemsPerPage={handleItemsPerPageChange}
          numberOfItemsOnCurrentPage={numberOfItemsOnCurrentPage}
          recordsName={"Device Sms Events"}
          isFiltered={isFiltered}
          totalNumberOfItems={totalNumberOfItems}
        />

        <Toast success={deviceCommandInfo.sendSuccess} show={deviceCommandInfo.showToast} message={"Sms sent to device"} />
        <ConfirmationDialog show={commandConfirmation.confirm} onNo={handleNegativeConfirmation} onYes={handlePositiveConfirmation} confirmationMessage={commandConfirmation.message} />
        <Table
          data={displayData}
          pagingInfo={{ dataPerPage: itemsPerPage, currentPage }}
          tableColumns={dynamicTableColumn}
          initialSort={{ key: "data_timestamp", order: "desc" }}
          onSelectedChanged={onSelectedTableRowChanged}
          includeSequentialId={true}
        />
        <Pagination handlePageClick={handlePageClick} numberOfPages={numberOfPages} />
      </section>
    </Layout>
  );
}
