import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Layout, PrimaryHeader, OnError, } from "../../components";
import { selectCurrentUser } from "../../features/auth/authSlice";
import Api from "../../api";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';



function VehicleAdd() {
    const [errors, setErrors] = useState({});
    const user = useSelector(selectCurrentUser);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const loadText = 'Adding Vehicle...';

    const [checkIfVehicleRegistrationNumberExists] = Api.vehicles.useLazyGetDoesVehicleExistsQuery();
    const [checkIfVINExists] = Api.vehicles.useLazyGetDoesVehicleExistsQuery();
    const [vehicleRegistrationNumberExists, setVehicleRegistrationNumberExists] = useState(false);
    const [VINExists, setVINExists] = useState(false);

    const [addNewVehicle] = Api.vehicles.usePostAddVehicleMutation();
    const [formData, setFormData] = useState({
      vehicleRegistrationNumber: '',
      make: '',
      model: '',
      colour: '',
      vehicleType: '',
      fuelType: '',
      year: '',
      licenceDiskNumber: '',
      vin: '',
      registrationNumber: '',
      engineNumber: '',
      operationHoursOnInstall: '',
      odometerOnInstall: '',
      installationLocation: '',
      installationDate: null,
      batteryTypeId: '',
      batteryVoltage: '',
      licenceExpiryDate: null,
      serviceDate: null,
      serviceIntervalMonths: '',
      description: '',
      notes: ''
      
    });

    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }

    const handleDateChange = (date, fieldName) => {
      setFormData({
        ...formData,
        [fieldName]: date,
      });
    };

    const checkVehicleRegistrationNumberExists = async (vehicleRegistrationNumber) => {
      console.log(vehicleRegistrationNumber);
  
      if (vehicleRegistrationNumber.trim() !== "") {
        try {
          const vehicleRegistrationNumberExist = await checkIfVehicleRegistrationNumberExists({vehicleRegistrationNumber}).unwrap();
          setVehicleRegistrationNumberExists(vehicleRegistrationNumberExist)
          console.log(vehicleRegistrationNumberExists);
        } catch (error) {
          console.error("Error checking vehicle registration number existence:", error, vehicleRegistrationNumber);
        }
      }
    };

    const checkVINExists = async (vin) => {
      console.log(vin);
  
      if (vin.trim() !== "") {
        try {
          const VINExist = await checkIfVINExists({vin}).unwrap();
          setVINExists(VINExist)
          console.log(VINExists);
        } catch (error) {
          console.error("Error checking vehicle identification number existence:", error,vin);
        }
      }
    };
  

      const submitNewVehicleToApi = async() => {

        return new Promise((resolve, reject) => {
          
          
        try {
          if (Object.keys(errors).length === 0) {
            // submit the form
          
            addNewVehicle(formData).unwrap()
              .then(addResults => {
    
                if (addResults) {
                  resolve(true);
                }
                else {
                  resolve(false)
                }
              }).catch(error => {
                console.log(error);
                console.error(error);
                reject(error);
              }) 
              
          }
        }
        catch (error) {
          console.error(error);
          reject(error);
        }
    
    
        })
        
      }
  

    const handleSubmitNewVehicle = async (event) => {
      event.preventDefault();
  
      const errorInfo = {};
      if (formData.vehicleRegistrationNumber.trim() === '') {
        errorInfo.vehicleRegistrationNumber = 'Vehicle registration number is required';
      } else if (formData.vehicleRegistrationNumber.trim().length > 20) {
        errorInfo.vehicleRegistrationNumber = 'Vehicle Registration Number must be 20 characters or less';
      } else if (vehicleRegistrationNumberExists) {
        errorInfo.vehicleRegistrationNumber = "Vehicle Registration Number already exists";
      }
      if (formData.make.trim() === '') {
        errorInfo.make = 'Make of the vehicle is required';
      }
      if (formData.colour.trim() === '') {
        errorInfo.colour = 'Colour of the vehicle is required';
      }
      if (formData.vehicleType.trim() === '') {
        errorInfo.vehicleType = 'Vehicle type is required';
      }
      if (formData.fuelType.trim() === '') {
        errorInfo.fuelType = 'Fuel type is required';
      }
      if (formData.year.trim() === '') {
        errorInfo.year = 'Year of the vehicle is required';
      }
      if (isNaN(formData.year)) {
        errorInfo.year = 'Year of the vehicle must be a valid number';
      }
      if (formData.vin.trim() === '') {
        errorInfo.vin = 'Vehicle identification number is required';
      }
      if (formData.vin.trim().length > 20) {
        errorInfo.vin = 'Vehicle identification number must be 20 characters or less';
      } else if (VINExists) {
        errorInfo.vin = "Vehicle identification number exists";
      } 
      if (formData.licenceDiskNumber.trim() === '') {
        errorInfo.licenceDiskNumber = 'Licence Disk Number is required';
      }
      if (formData.registrationNumber.trim() === '') {
        errorInfo.registrationNumber = 'Registration Number is required';
      }
      if (formData.engineNumber.trim() === '') {
        errorInfo.engineNumber = 'Engine Number is required';
      }
      if (formData.operationHoursOnInstall.trim() === '') {
        errorInfo.operationHoursOnInstall = 'Operation Hours On Install is required';
      }
      if (isNaN(formData.operationHoursOnInstall)) {
        errorInfo.operationHoursOnInstall = 'Operation Hours On Install must be a valid number';
      }
      if (formData.odometerOnInstall.trim() === '') {
        errorInfo.odometerOnInstall = 'Odometer On Install is required';
      }
      if (formData.installationLocation.trim() === '') {
        errorInfo.installationLocation = 'Installation Location is required';
      }
      if (formData.installationDate === null) {
        errorInfo.installationDate = 'Installation Date is required';
      }
      if (formData.batteryTypeId.trim() === '') {
        errorInfo.batteryTypeId = 'Battery Type ID is required';
      }
      if (formData.batteryVoltage.trim() === '') {
        errorInfo.batteryVoltage = 'Battery Voltage is required';
      }
      if (formData.licenceExpiryDate === null) {
        errorInfo.licenceExpiryDate = 'Licence Expiry Date is required';
      }
      if (formData.serviceDate === null) {
        errorInfo.serviceDate = 'Service Date is required';
      }
      if (formData.serviceIntervalMonths.trim() === '') {
        errorInfo.serviceIntervalMonths = 'Service Interval Months is required';
      }
      if (isNaN(formData.serviceIntervalMonths)) {
        errorInfo.serviceIntervalMonths = 'Service Interval Months must be a valid number';
      }
  
      setErrors(errorInfo);
      setLoading(true);
  
      if (Object.keys(errorInfo).length === 0) {
        try {
          const addResults = await submitNewVehicleToApi({
            ...formData,
            createdBy: user.id,
            createdAt: new Date(),
          });
          console.log(addResults)
          if (addResults) {
            navigate("/vehicles");
          } else {
            console.error(addResults);
          }
        } catch (error) {
          console.log(error);
          console.error(error);
        }finally {
          setLoading(false); // Set loading to false after the submission attempt
        }
      } else {
        setLoading(false);
      }
      
    };

    
  return  (
    <Layout isForegroundProcess={loading} loadingText={loadText}>
      <PrimaryHeader title="Add New Vehicle">
        <div className="flex">
          </div>
      </PrimaryHeader>
      
      <section className="flex flex-col">
       


        <div className="input-card">

        <form onSubmit={handleSubmitNewVehicle} className="flex flex-wrap items-center">
          {
            errors.response ? (<div className="flex w-full mb-4 text-primary">
              <p className="px-2 text-sm text-red-500">{errors.response}</p>)
            </div>): null
          }
      
      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="vehicleRegistrationNumber" className="w-1/4 pr-4">
          Vehicle Registration Number:
        </label>
        <input
          type="text"
          id="vehicleRegistrationNumber"
          name="vehicleRegistrationNumber"
          value={formData.vehicleRegistrationNumber}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.vehicleRegistrationNumber ? 'border-red-500' : ''}`}
          />
{errors.vehicleRegistrationNumber ? (<p className="pl-1 text-sm text-red-500 ">{errors.vehicleRegistrationNumber}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="make" className="w-1/4 pr-4">
          Make:
        </label>
        <input
          type="text"
          id="make"
          name="make"
          value={formData.make}
          onChange={handleInputChange}
          onBlur={() => {
            checkVehicleRegistrationNumberExists(formData.vehicleRegistrationNumber);
          }}
          className={`w-3/4 full-width-input-field ${errors.make ? 'border-red-500' : ''}`}
          />
{errors.make ? (<p className="pl-1 text-sm text-red-500 ">{errors.make}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="colour" className="w-1/4 pr-4">
          Colour:
        </label>
        <input
          type="text"
          id="colour"
          name="colour"
          value={formData.colour}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.colour ? 'border-red-500' : ''}`}
          />
{errors.colour ? (<p className="pl-1 text-sm text-red-500 ">{errors.colour}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="vehicleType" className="w-1/4 pr-4">
        Vehicle Type:
        </label>
        <input
          type="text"
          id="vehicleType"
          name="vehicleType"
          value={formData.vehicleType}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.vehicleType ? 'border-red-500' : ''}`}
          />
{errors.vehicleType ? (<p className="pl-1 text-sm text-red-500 ">{errors.vehicleType}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="fuelType" className="w-1/4 pr-4">
        Fuel Type:
        </label>
        <input
          type="text"
          id="fuelType"
          name="fuelType"
          value={formData.fuelType}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.fuelType ? 'border-red-500' : ''}`}
          />
{errors.fuelType ? (<p className="pl-1 text-sm text-red-500 ">{errors.fuelType}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="year" className="w-1/4 pr-4">
        Year:
        </label>
        <input
          type="number"
          id="year"
          name="year"
          value={formData.year}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.year ? 'border-red-500' : ''}`}
          />
{errors.year ? (<p className="pl-1 text-sm text-red-500 ">{errors.year}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="vin" className="w-1/4 pr-4">
          Vehicle Identification Number:
        </label>
        <input
          type="text"
          id="vin"
          name="vin"
          value={formData.vin}
          onChange={handleInputChange}
          onBlur={() => {
            checkVINExists(formData.vin);
          }}
          className={`w-3/4 full-width-input-field ${errors.vin ? 'border-red-500' : ''}`}
          />
{errors.vin ? (<p className="pl-1 text-sm text-red-500 ">{errors.vin}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="licenceDiskNumber" className="w-1/4 pr-4">
        Licence Disk Number:
        </label>
        <input
          type="text"
          id="licenceDiskNumber"
          name="licenceDiskNumber"
          value={formData.licenceDiskNumber}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.licenceDiskNumber ? 'border-red-500' : ''}`}
          />
{errors.licenceDiskNumber ? (<p className="pl-1 text-sm text-red-500 ">{errors.licenceDiskNumber}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="registrationNumber" className="w-1/4 pr-4">
        Registration Number:
        </label>
        <input
          type="text"
          id="registrationNumber"
          name="registrationNumber"
          value={formData.registrationNumber}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.registrationNumber ? 'border-red-500' : ''}`}
          />
{errors.registrationNumber ? (<p className="pl-1 text-sm text-red-500 ">{errors.registrationNumber}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="engineNumber" className="w-1/4 pr-4">
        Engine Number:
        </label>
        <input
          type="text"
          id="engineNumber"
          name="engineNumber"
          value={formData.engineNumber}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.engineNumber ? 'border-red-500' : ''}`}
          />
{errors.engineNumber ? (<p className="pl-1 text-sm text-red-500 ">{errors.engineNumber}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="operationHoursOnInstall" className="w-1/4 pr-4">
        Operation Hours On Install:
        </label>
        <input
          type="number"
          id="operationHoursOnInstall"
          name="operationHoursOnInstall"
          value={formData.operationHoursOnInstall}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.operationHoursOnInstall ? 'border-red-500' : ''}`}
          />
{errors.operationHoursOnInstall ? (<p className="pl-1 text-sm text-red-500 ">{errors.operationHoursOnInstall}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="odometerOnInstall" className="w-1/4 pr-4">
        Odometer On Install:
        </label>
        <input
          type="number"
          id="odometerOnInstall"
          name="odometerOnInstall"
          value={formData.odometerOnInstall}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.odometerOnInstall ? 'border-red-500' : ''}`}
          />
{errors.odometerOnInstall ? (<p className="pl-1 text-sm text-red-500 ">{errors.odometerOnInstall}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="installationLocation" className="w-1/4 pr-4">
        Installation Location:
        </label>
        <input
          type="text"
          id="installationLocation"
          name="installationLocation"
          value={formData.installationLocation}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.installationLocation ? 'border-red-500' : ''}`}
          />
{errors.installationLocation ? (<p className="pl-1 text-sm text-red-500 ">{errors.installationLocation}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="installationDate" className="w-1/4 pr-4">
        Installation Date:
        </label>
        <DatePicker
          id="installationDate"
          name="installationDate"
          selected={formData.installationDate}
          onChange={(date) => handleDateChange(date, 'installationDate')}
          dateFormat="dd/MM/yyyy"
          className={`w-3/4 full-width-input-field ${errors.installationDate ? 'border-red-500' : ''}`}
          />
{errors.installationDate ? (<p className="pl-1 text-sm text-red-500 ">{errors.installationDate}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="batteryTypeId" className="w-1/4 pr-4">
        Battery Type ID:
        </label>
        <input
          type="number"
          id="batteryTypeId"
          name="batteryTypeId"
          value={formData.batteryTypeId}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.batteryTypeId ? 'border-red-500' : ''}`}
          />
{errors.batteryTypeId ? (<p className="pl-1 text-sm text-red-500 ">{errors.batteryTypeId}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="batteryVoltage" className="w-1/4 pr-4">
        Battery Voltage:
        </label>
        <input
          type="number"
          id="batteryVoltage"
          name="batteryVoltage"
          value={formData.batteryVoltage}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.batteryVoltage ? 'border-red-500' : ''}`}
          />
{errors.batteryVoltage ? (<p className="pl-1 text-sm text-red-500 ">{errors.batteryVoltage}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="licenceExpiryDate" className="w-1/4 pr-4">
        Licence Expiry Date:
        </label>
        <DatePicker
          id="licenceExpiryDate"
          name="licenceExpiryDate"
          selected={formData.licenceExpiryDate}
          onChange={(date) => handleDateChange(date, 'licenceExpiryDate')}
          dateFormat="dd/MM/yyyy"
          className={`w-3/4 full-width-input-field ${errors.licenceExpiryDate ? 'border-red-500' : ''}`}
          />
{errors.licenceExpiryDate ? (<p className="pl-1 text-sm text-red-500 ">{errors.licenceExpiryDate}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="serviceDate" className="w-1/4 pr-4">
        Service Date:
        </label>
        <DatePicker
          id="serviceDate"
          name="serviceDate"
          selected={formData.serviceDate}
          onChange={(date) => handleDateChange(date, 'serviceDate')}
          dateFormat="dd/MM/yyyy"
          className={`w-3/4 full-width-input-field ${errors.serviceDate ? 'border-red-500' : ''}`}
          />
{errors.serviceDate ? (<p className="pl-1 text-sm text-red-500 ">{errors.serviceDate}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="serviceIntervalMonths" className="w-1/4 pr-4">
        Service Interval Months:
        </label>
        <input
          type="number"
          id="serviceIntervalMonths"
          name="serviceIntervalMonths"
          value={formData.serviceIntervalMonths}
          onChange={handleInputChange}
          className={`w-3/4 full-width-input-field ${errors.serviceIntervalMonths ? 'border-red-500' : ''}`}
          />
{errors.serviceIntervalMonths ? (<p className="pl-1 text-sm text-red-500 ">{errors.serviceIntervalMonths}</p>) : null}             
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="description" className="w-1/4 pr-4">
        Description:
        </label>
        <input
          type="text"
          id="description"
          name="description"
          value={formData.description}
          onChange={handleInputChange}
          className="w-3/4 full-width-input-field"
          />           
      </div>

      <div className="flex w-full mb-4 text-primary">
        <label htmlFor="notes" className="w-1/4 pr-4">
        Notes:
        </label>
        <input
          type="text"
          id="notes"
          name="notes"
          value={formData.notes}
          onChange={handleInputChange}
          className="w-3/4 full-width-input-field"
          />
      </div>
         
          
      <div className="w-1/4 py-4 mx-4 text-primary">
          <button type="submit" className="w-1/2 btn-primary">
            Add New Vehicle
          </button>
      </div>
        </form>
          </div>
        
      </section>
    </Layout>
  );
}

export default VehicleAdd