import React from "react";
import ClockLoader from "react-spinners/ClockLoader";
import RotateLoader from "react-spinners/RotateLoader";



function Loading({ isLoading, loadingText, isForegroundProcess, isLogin = false, isRegister = false }) {
  let extraStyling = isLogin || isRegister;
  
  
  return (isLoading ? <ShowLoading isLoading={isLoading} loadingText={loadingText} isForegroundProcess={isForegroundProcess} extraStyling={extraStyling} /> : null  );
}

function ShowLoading({ loadingText, isLoading, isForegroundProcess, extraStyling }) {

  return (
    <section className={`flex items-center justify-center flex-col gap-1 overflow-hidden h-screen absolute ${extraStyling ? "h-screen w-full justify-center" : "absolute inset-0 z-[80]"}`}>
      <div className={`flex flex-col items-center justify-center h-72 gap-1 rounded-md min-w-72 ${!extraStyling ? "bg-background p-4" : ""}`}>
        {isForegroundProcess ? <ShowRotateLoading isLoading={isLoading} loadingText={loadingText} /> :
          <ShowClockLoading isLoading={isLoading} loadingText={loadingText} />} 
        <span className={`${isForegroundProcess ? "pt-20" : "pt-2"} text-xl capitalize text-primary animate-pulse`}>{loadingText}</span>
      </div>
    </section>
  );
}

function ShowClockLoading({isLoading}) { 
  return (<ClockLoader color="var(--color-brand)" size={150} loading={isLoading} />);
}

function ShowRotateLoading({isLoading}) { 
  return (<RotateLoader color="var(--color-brand)" size={15} margin={30} loading={isLoading} />);
}

export default React.memo(Loading);
