import React from "react";
import { useLoadScript,useJsApiLoader } from "@react-google-maps/api";
import systemConfig from "../../../config/systemConfiguration";
import Map from "./Map";

const { apiKey : googleMapsApiKey } = systemConfig.maps.google;

 function GoogleMap({ vehicles }) {
  
    const { isLoaded } = useJsApiLoader({
      googleMapsApiKey
    });
  
    if (!isLoaded) {
        return <div>Loading...</div>;
    }

    return <Map vehicles={vehicles}/>;
}
  
export default React.memo(GoogleMap)